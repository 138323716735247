<template>
  <div id="app">
    <FreeBusyDatePicker />
  </div>
</template>

<script>
import FreeBusyDatePicker from './components/FreeBusyDatePicker.vue'

export default {
  name: 'App',
  components: {
    FreeBusyDatePicker
  }
}
</script>
