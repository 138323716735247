<template>
  <div class="wrap">
    <div class="left">
      <DayPilotNavigator id="nav" :config="datePickerConfig" ref="datePicker" :events="events" />
    </div>
    <div class="content">
      <DayPilotCalendar id="dp" :config="calendarConfig" ref="calendar" :events="events" />
    </div>
  </div>
</template>

<script>
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from '@daypilot/daypilot-lite-vue'

export default {
  name: 'FreeBusyDatePicker',
  data: function() {
    return {
      events: [],
      datePickerConfig: {
        showMonths: 2,
        skipMonths: 2,
        selectMode: "Month",
        startDate: "2023-08-10",
        selectionDay: "2023-08-10",
        onTimeRangeSelected: args => {
          this.calendarConfig.startDate = args.day;
        }
      },
      calendarConfig: {
        viewType: "Week",
        startDate: "2023-08-10",
        timeRangeSelectedHandling: "Enabled",
        onTimeRangeSelected: async (args) => {
          const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
          const dp = args.control;
          dp.clearSelection();
          if (modal.canceled) {
            return;
          }
          dp.events.add({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            text: modal.result
          });
        },
        eventDeleteHandling: "Disabled",
        onEventMoved: () => {
          console.log("Event moved");
        },
        onEventResized: () => {
          console.log("Event resized");
        },
      },
    }
  },
  props: {
  },
  components: {
    DayPilotCalendar,
    DayPilotNavigator
  },
  computed: {
    // DayPilot.Calendar object - https://api.daypilot.org/daypilot-calendar-class/
    calendar() {
      return this.$refs.calendar.control;
    },
    datePicker() {
      return this.$refs.datePicker.control;
    }
  },
  methods: {
    loadEvents() {

      // placeholder for an HTTP call
      const events = [
        {
          id: 1,
          start: "2023-08-10T10:00:00",
          end: "2023-08-10T11:00:00",
          text: "Event 1",
          barColor: "#6aa84f",
        },
        {
          id: 2,
          start: "2023-08-15T13:00:00",
          end: "2023-08-15T16:00:00",
          text: "Event 2",
          barColor: "#f1c232",
        },
        {
          id: 3,
          start: "2023-08-08T13:30:00",
          end: "2023-08-08T16:30:00",
          text: "Event 3",
          barColor: "#cc4125",
        },
        {
          id: 4,
          start: "2023-08-10T10:30:00",
          end: "2023-08-10T12:30:00",
          text: "Event 4"
        },
        {
          id: 5,
          start: "2023-08-15T10:30:00",
          end: "2023-08-15T12:30:00",
          text: "Event 5"
        },
        {
          id: 6,
          start: "2023-08-10T10:30:00",
          end: "2023-08-10T12:30:00",
          text: "Event 6"
        },
      ];
      this.events = events;
    },
  },
  mounted() {
    this.loadEvents();
  }
}
</script>
<style>
.wrap {
  display: flex;
}

.left {
  margin-right: 10px;
}

.content {
  flex-grow: 1;
}

.calendar_default_event_inner {
  border-radius: 5px;
}
.navigator_default_busy.navigator_default_cell {
  background-color: #ee4f2e;
  border-radius: 15px;
  color: white;
}

</style>
